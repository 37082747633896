import React, { useState, useEffect } from 'react';
import Toot from './Toot';
import axios from 'axios';

const GtsBookmarks = () => {
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://thenighthas.me/api/v1/bookmarks', {
            mode: 'no-cors',
            headers: {
                Authorization: 'Bearer YJHJMWU1YJITM2ZKYI0ZY2YYLWJJZTATNMJLYTIWYZDMNJAW'
            },
            crossDomain: true,
        });
        setApiData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {apiData ? (
        // Render your component using the fetched data
        <Toot data={apiData} />
      ) : (
        // Render a loading state or placeholder
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GtsBookmarks;