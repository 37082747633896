import logo from './logo.svg';
import './App.css';
import MastodonBookmarks from './components/MastodonBookmarks';
import GtsBookmarks from './components/GtsBookmarks';

function App() {
  return (
    <div className="App">
      <MastodonBookmarks />
      <GtsBookmarks />
    </div>
  );
}

export default App;
