import { Component } from "react";

const Toot = ({ data }) => {
    return (
      <div>
        {data.map((item) => (
          <div key={item.id} className="toot">
            <div>
              <a href={item.url}>{item.account.acct}</a>
            </div>
            <div dangerouslySetInnerHTML={{ __html: item.content}} />
          </div>
        ))}
      </div>
    );
  };
  
  export default Toot;
