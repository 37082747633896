import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Toot from './Toot';

const MastodonBookmarks = () => {
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://zirk.us/api/v1/bookmarks', {
            mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer 7tT4RttvK1-npnITaQvH41jkHipPhRLOvsmySxLUDZk '
            },
            crossDomain: true,
        });
        setApiData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {apiData ? (
        // Render your component using the fetched data
        <Toot data={apiData} />
      ) : (
        // Render a loading state or placeholder
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MastodonBookmarks;